import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
	ContentWrapper,
	FooterBottomContainer,
	NavigationFooterWrapper,
	SocialLogoWrapper,
	PhoneEmailContainer,
} from "./style";
import {
	HorizontalList,
	Separator,
	Text,
	Link,
	CallUsModal,
} from "@website-builder/ui/shared/elements";
import VerticalLinksList from "./VerticalLinksList";
import { useMediaQuery } from "@react-hook/media-query";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { addToDataLayer } from "@website-builder/utilities/utils/utils.js";
import { MOBILE_L_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import CloudinaryImage from "../../elements/CloudinaryImage";

const NavigationFooter = ({
	isPaidLander,
	logo,
	column1Items = [],
	column2Items = [],
	column3Items = [],
	column4Items = [],
	column5Items = [],
	additionalLinks = [],
	legalLinks = [],
	socialLinks = [],
	phoneNumber,
	callUsModalDetails,
	email,
	isB2c = false,
	pageSection,
	context,
	basicConfig = null,
	className,
	isLogoCenteredInMobile = false,
}) => {
	const brandLogoSrc =
		"https://res.cloudinary.com/springboard-images/image/upload/v1677268226/website-redesign/brand-logos/springboard-logo-rev-2023.svg";
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${MOBILE_L_BP}px)`);
	const [isMobile, setIsMobile] = useState(undefined);
	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	const isB2u = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";

	const [showCallUsModal, setShowCallUsModal] = useState(false);
	const handleCallUsModalLink = (event) => {
		event.preventDefault();
		setShowCallUsModal(true);
	};

	const getLogo = () => {
		if (logo?.hasOwnProperty("type") && basicConfig) {
			if (logo.type === "primary") {
				return basicConfig?.primaryLogo;
			} else if (logo.type === "secondary") {
				return basicConfig?.secondaryLogo;
			} else if (logo.type === "custom") {
				return logo?.image;
			}
		}
		return null;
	};

	const buildColumn = (column, index) => {
		if (isB2u) {
			// in B2U address will always be in the first column
			if (index === 0) {
				const address = column[0]?.text;
				return (
					<Text
						variant="paragraph_M"
						className="university-address rich-text-wrapper"
						tag="div"
						color={{ base: "primary", color: "neutral", shade: "white" }}
						dangerouslySetInnerHTML={{ __html: address }}
					/>
				);
			}
			if (column[0]?.component === "textLinkWR") {
				// FAQ and BLOG links for B2U
				const item = column[0];
				return (
					<Link
						className="clickable-label"
						href={item?.link?.link}
						target={item?.link?.target}
						onClick={(e) =>
							handleCTAClick(
								item,
								{
									page_section: pageSection,
									click_text: item?.text,
									redirect_to: item?.link?.link,
									event: item?.link?.event,
								},
								{ context },
								e,
							)
						}
					>
						<Text
							variant="label_M"
							color={{ base: "primary", color: "neutral", shade: "white" }}
						>
							{column[0].text}
						</Text>
					</Link>
				);
			}
		}
		return (
			<div
				className={`course-column ${column?.length ? null : "empty-column"}`}
			>
				{column?.length
					? column?.map((item, index) => (
							<VerticalLinksList
								className="vertical-list"
								listDetail={item}
								isPaidLander={isPaidLander}
								key={index}
								pageSection={pageSection}
							/>
						))
					: null}
			</div>
		);
	};

	const getContent = () => {
		if (isPaidLander) {
			return (
				<ContentWrapper isPaidLander={true}>
					<div className="course-columns">
						{[
							column1Items,
							column2Items,
							column3Items,
							column4Items,
							column5Items,
						].map((column, index) => (
							<React.Fragment key={index}>{buildColumn(column)}</React.Fragment>
						))}
					</div>
				</ContentWrapper>
			);
		} else {
			return (
				<ContentWrapper isPaidLander={false}>
					<div className="left-section">
						<div className="course-columns">
							{[column1Items, column2Items].map((column, index) => (
								<React.Fragment key={index}>
									{buildColumn(column, index)}
								</React.Fragment>
							))}
						</div>
						{additionalLinks.length ? (
							<>
								<Separator darkBg={true} />
								<div className="additional-links">
									{additionalLinks?.map((item, index) => (
										<Link
											href={item?.link?.link}
											target={item?.link?.target}
											onClick={(e) =>
												handleCTAClick(
													item,
													{
														page_section: pageSection,
														click_text: item.linkText,
														redirect_to: item.link?.link,
													},
													{ context },
													e,
												)
											}
											key={index}
										>
											<Text
												variant="paragraph_S"
												className="link-text"
												color={{
													base: "primary",
													color: "neutral",
													shade: "white",
												}}
											>
												{item.linkText}
											</Text>
										</Link>
									))}
								</div>
							</>
						) : null}
					</div>
					<div className="right-section course-columns">
						{[column3Items, column4Items, column5Items].map((column, index) => (
							<React.Fragment key={index}>{buildColumn(column)}</React.Fragment>
						))}
					</div>
				</ContentWrapper>
			);
		}
	};
	const legalListItems = legalLinks?.map((legalLink, index) => {
		return (
			<Link
				href={legalLink?.cta?.link?.link}
				target={legalLink?.cta?.link?.target}
				onClick={(e) =>
					handleCTAClick(
						legalLink.cta,
						{
							page_section: pageSection,
							click_text: legalLink.text,
							redirect_to: legalLink.cta?.link?.link,
						},
						{},
						e,
					)
				}
				key={index}
			>
				<Text
					variant={isMobile ? "paragraph_S_bold" : "paragraph_S"}
					className="link-text"
					color={{ base: "primary", color: "neutral", shade: "white" }}
					style={{ fontSize: "0.75rem" }}
				>
					{legalLink.text}
				</Text>
			</Link>
		);
	});

	const footerEmailDataEvent = () => {
		addToDataLayer({
			event: BUTTON_INTERACTION.LINK_CLICK,
			redirect_to: `mailTo:${email}`,
			click_text: email,
			page_section: pageSection,
		});
	};

	const contactUsSection = !isPaidLander ? (
		<div className="contact-us-section">
			<PhoneEmailContainer className="phone-email-container">
				<Link
					onClick={handleCallUsModalLink}
					darkBg={true}
					as="button"
					className="call-us-link"
					aria-haspopup="dialog"
					aria-controls={showCallUsModal ? "footer-call-us-modal" : null}
					aria-expanded={showCallUsModal}
				>
					<Text
						variant="paragraph_S"
						className="link-text"
						color={{ base: "primary", color: "neutral", shade: "white" }}
					>
						{phoneNumber}
					</Text>
				</Link>
				<CallUsModal
					showModal={showCallUsModal}
					onClose={() => setShowCallUsModal(false)}
					tel={phoneNumber}
					{...callUsModalDetails}
					imageUrl={callUsModalDetails?.image?.image}
					imageAltText={callUsModalDetails?.image?.altTag}
					pageSection={pageSection}
					id="footer-call-us-modal"
				/>
				{phoneNumber && email ? (
					<Separator
						vertical
						color="var(--primary-neutral-white)"
						className="footer-separator"
					/>
				) : null}
				<Link href={`mailTo:${email}`} darkBg={true}>
					<Text
						variant="paragraph_S"
						className="link-text"
						color={{ base: "primary", color: "neutral", shade: "white" }}
					>
						{email}
					</Text>
				</Link>
			</PhoneEmailContainer>
		</div>
	) : null;

	return (
		<NavigationFooterWrapper
			isLogoCenteredInMobile={isLogoCenteredInMobile}
			logoWidth={logo?.width}
			className={className}
			isB2c={isB2c}
		>
			<h2 className="sb-sr-only">Page Footer</h2>
			<div className="mobile-container-with-margin">
				<div className="logo-container">
					<Link href="/">
						<img
							src={getLogo() || logo?.image || brandLogoSrc}
							alt={logo?.altTag || "Brand Logo"}
							width={logo?.width || "250px"}
							height={logo?.height || "40px"}
							aria-label={
								isB2u ? "University bootcamp homepage" : "Springboard homepage"
							}
						/>
					</Link>
					<div className="social-links-container">
						{!isPaidLander
							? socialLinks?.map((socialLink, index) => (
									<SocialLogoWrapper key={index}>
										<Link
											href={socialLink?.cta?.link?.link}
											target={socialLink?.cta?.link?.target}
											onClick={(e) =>
												handleCTAClick(
													socialLink.cta,
													{
														page_section: pageSection,
														redirect_to: socialLink.cta?.link?.link,
													},
													{},
													e,
												)
											}
											aria-label={
												`${socialLink.icon?.altTag} link` ||
												`social link${index + 1}`
											}
										>
											<CloudinaryImage
												className="social-logo-img"
												url={socialLink.icon?.imageUrl}
												quality="auto"
												lazyload
												fetchFormat="auto"
												alt={
													socialLink.icon?.altTag || `social icon${index + 1}`
												}
											/>
										</Link>
									</SocialLogoWrapper>
								))
							: null}
					</div>
					{isMobile && !isPaidLander ? contactUsSection : null}
				</div>
				{getContent()}
			</div>
			<Separator darkBg={true} />
			<FooterBottomContainer isPaidLander={isPaidLander}>
				{!isMobile ? contactUsSection : null}
				<div className="legal-section">
					<div className="copyright">
						<Text
							variant="paragraph_S"
							className="link-text"
							color={{ base: "primary", color: "neutral", shade: "white" }}
							style={{ fontSize: "0.75rem" }}
						>
							&copy; Copyright {new Date().getFullYear()}
						</Text>
					</div>
					{isB2u ? (
						<React.Fragment>
							<Separator
								vertical
								color="var(--primary-neutral-white)"
								className="footer-separator powered-by-sb-separator"
							/>
							<Text
								variant="paragraph_S"
								className="powered-by-springboard-text"
								color={{ base: "primary", color: "neutral", shade: "white" }}
								style={{ fontSize: "0.75rem" }}
							>
								Powered by Springboard
							</Text>
						</React.Fragment>
					) : null}
					{!isMobile && legalListItems.length ? (
						<Separator
							vertical
							color="var(--primary-neutral-white)"
							className="footer-separator"
						/>
					) : null}
					<HorizontalList
						className="legal-items"
						listItems={legalListItems}
						centered={true}
					/>
				</div>
			</FooterBottomContainer>
		</NavigationFooterWrapper>
	);
};

const columnShape = PropTypes.arrayOf(
	PropTypes.shape({
		links: PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string,
				showInPaid: PropTypes.bool,
				cta: PropTypes.shape({
					event: PropTypes.string,
					link: PropTypes.shape({
						link: PropTypes.string,
						target: PropTypes.string,
						type: PropTypes.string,
					}),
				}),
			}),
		),
		title: PropTypes.string,
	}),
);

NavigationFooter.propTypes = {
	isPaidLander: PropTypes.bool,
	column1Items: columnShape,
	column2Items: columnShape,
	column3Items: columnShape,
	column4Items: columnShape,
	column5Items: columnShape,
	additionalLinks: PropTypes.arrayOf(PropTypes.object),
	legalLinks: PropTypes.arrayOf(PropTypes.object),
	socialLinks: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.shape({
				altTag: PropTypes.string,
				imageUrl: PropTypes.string,
			}),
			cta: PropTypes.shape({
				event: PropTypes.string,
				link: PropTypes.object,
			}),
		}),
	),
	phoneNumber: PropTypes.string,
	email: PropTypes.string,
	isB2c: PropTypes.bool,
};

export default NavigationFooter;
