import React from "react";
import { ListDetailContainer } from "./style";
import { Link, Text, VerticalList } from "@website-builder/ui/shared/elements";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const VerticalLinksList = ({
	listDetail,
	isPaidLander = false,
	pageSection,
}) => {
	let filteredListItems = [];
	const listItems = listDetail?.links?.map((item, index) => {
		const jsx = (
			<Link
				className="link-container"
				href={item?.cta?.link?.link}
				onClick={(e) =>
					handleCTAClick(
						item.cta,
						{
							page_section: pageSection,
							redirect_to: item.cta?.link?.link,
							click_text: item.text,
						},
						{},
						e,
					)
				}
			>
				<Text
					className="footer-links"
					variant="paragraph_S"
					color={{ base: "primary", color: "neutral", shade: "white" }}
				>
					{item.text}
				</Text>
			</Link>
		);
		if (item.showInPaid) {
			filteredListItems.push(
				<React.Fragment key={index}>{jsx}</React.Fragment>,
			);
		}
		return <React.Fragment key={index}>{jsx}</React.Fragment>;
	});

	if (isPaidLander && filteredListItems.length === 0) {
		return null;
	}

	return (
		<ListDetailContainer isPaidLander={isPaidLander}>
			{isPaidLander ? null : (
				<Text className="list-category-name" variant="label_M">
					{listDetail.title}
				</Text>
			)}
			{isPaidLander ? (
				<VerticalList listItems={filteredListItems} gap={"0.5rem"} />
			) : (
				<VerticalList listItems={listItems} gap={"0.5rem"} />
			)}
		</ListDetailContainer>
	);
};

export default VerticalLinksList;
