import styled from "styled-components";
export const ListDetailContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: fit-content;
	min-width: 120px;
	margin-bottom: ${(p) => (p.isPaidLander ? "1rem" : "1.5rem")};
	& p {
		white-space: normal;
	}
	.list-category-name {
		text-transform: uppercase;
		color: var(--theme-light-font-color, #7be3aa);
		display: block;
		margin-bottom: 1rem;
	}
	.footer-links {
		font-size: 14px;
		font-weight: 400;
	}
`;
