import styled from "styled-components";
import {
	DESKTOP_SM_BP,
	DESKTOP_M_BP,
	MOBILE_L_BP,
	TABLET_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

const isB2u = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";

export const NavigationFooterWrapper = styled.footer`
  padding: ${(p) => (p.isB2c ? "48px" : "16px")} 96px 64px;
  @media only screen and (max-width: ${TABLET_M_BP}px) {
    padding: 16px 32px 32px 32px;
    img {
      width: 100%;
      height: auto;
      max-width: ${(p) => (p.logoWidth ? `${p.logoWidth}px` : "250px")};
    }
  }
  background: var(--primary-forest-600);
  .logo-container {
    display: flex;
    gap: 1rem 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .social-links-container {
      display: flex;
    }
    @media only screen and (max-width: ${MOBILE_L_BP}px) {
      flex-direction: column;
      justify-content: ${({ isLogoCenteredInMobile }) =>
				isLogoCenteredInMobile ? "center" : "space-between"};
      & > div {
        ${({ isLogoCenteredInMobile }) =>
					isLogoCenteredInMobile && "margin: auto;"}}
      }
    }
  }
  @media only screen and (max-width: ${MOBILE_L_BP}px) {
    padding: 1rem 1.5rem;
    padding: 0;
    overflow: hidden;
  }

  .mobile-container-with-margin {
    padding: 0;
    margin: 0;
  }
  @media only screen and (max-width: ${MOBILE_L_BP}px) {
    .mobile-container-with-margin {
      padding: 24px 24px 48px 24px;
      margin: 0;
    }
  }
`;

export const SocialLogoWrapper = styled.div`
	&:not(:last-child) {
		padding-right: 1rem;
	}
	img {
		max-height: 40px;
	}
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	padding: ${(p) => (p.isPaidLander ? "40px 0" : "64px 0 20px 0")};
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: 0;
		padding-top: 32px;
		${isB2u &&
		`
      flex-direction: column;
    `}
	}
	column-gap: 64px;
	width: 100%;
	align-items: stretch;
	.course-columns {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		column-gap: 32px;
		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			${isB2u &&
			`
        flex-direction: column;
      `}
		}
	}
	.university-address {
		flex: 1;
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			display: none;
		}
	}
	*:has(> .clickable-label) {
		flex: 1;
		text-decoration: none;
		color: var(--theme-light-font-color);
		& label {
			display: block;
		}
		& * {
			cursor: pointer;
		}
		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			margin: 16px 0;
		}
	}
	.course-column {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.empty-column {
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			display: none;
		}
	}
	.left-section {
		flex: ${isB2u ? 1 : 2};
		margin-bottom: 32px;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.left-section {
			margin-bottom: 20px;
		}
	}

	.right-section {
		flex: ${isB2u ? 1 : 3};
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			flex: ${isB2u ? 3 : 2};
		}
		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			${!isB2u && `display: none;`}
		}
	}
	.additional-links {
		display: flex;
		margin-top: 24px;
		column-gap: 32px;
		& > * {
			flex: 1;
		}
	}
`;

export const PhoneEmailContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 1rem;
	row-gap: 0.5rem;
	align-items: stretch;

	.call-us-link {
		background: transparent;
		border: none;
		box-shadow: none;
	}
`;

export const FooterBottomContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	row-gap: 16px;
	column-gap: 32px;
	flex-direction: ${(p) => (p.isPaidLander ? "row" : "row-reverse")};
	justify-content: space-between;
	padding-top: 20px;
	.phone-email-container {
		align-items: center;
	}
	.footer-separator {
		height: 10px;
		min-height: unset;
	}
	.powered-by-springboard-text {
		margin: 0 15px;
	}
	@media only screen and (max-width: ${DESKTOP_M_BP}px) {
		justify-content: ${(p) =>
			p.isPaidLander ? "space-between" : "space-around"};
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		flex-direction: column;
		gap: 1.5rem;
		align-items: center;
	}
	.legal-section {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		row-gap: 0.5rem;
		.legal-items {
			margin-left: 15px;
			row-gap: 0.4rem;
			@media only screen and (max-width: ${MOBILE_L_BP}px) {
				margin-top: 1rem;
				margin-left: 0;
			}
			& > div {
				row-gap: 0.5rem;
			}
		}
		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			flex-direction: column;
			padding: 32px 24px 24px 24px;
			.powered-by-sb-separator {
				display: none;
			}
		}
	}
	.copyright {
		margin-right: 15px;
		color: var(--primary-neutral-white);
		font-size: 14px;
		font-weight: 400;
		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			text-align: center;
			margin-right: 0;
		}
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		flex-direction: column;
		padding-top: 0;
	}
`;
